import React from "react";
import { Link } from "react-router-dom";
import "../asset/fonts/font-awesome.min.css";
import img2 from "../asset/img/user.png";
import '../Dashboard/fontR.css'

export const AdminPage = () => {
  return (
    <>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          <nav className="main-header navbar navbar-expand bg-white navbar-light border-bottom">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="#">
                  <i className="fa fa-bars" />
                </a>
              </li>
            </ul>
          </nav>
          <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <p className="pok">مدیر سیستم</p>
            <div className="sidebar">
              <div>
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                  <div className="image">
                    <img
                      alt="User Image"
                      className="img-circle elevation-2"
                      src={img2}
                    />
                  </div>
                  <div className="info" style={{ textAlign: "center" }}>
                    <a
                      className="d-block pok"
                      style={{ textAlign: "center" }}
                      href="#"
                    >
                      مدیر سیستم
                    </a>
                  </div>
                </div>
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-accordion="false"
                    data-widget="treeview"
                    role="menu"
                  >
                    <li className="nav-item has-treeview menu-open">
                      <a className="nav-link active" href="#">
                        <i className="nav-icon fa fa-dashboard" />
                        <p className="pok">
                          تنظیمات محتوای برنامه
                          <i className="right fa fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <Link to={"/panelAdmin/Mainx"}>
                          <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">صفحه اصلی برنامه</p>
                            </a>
                          </li>
                        </Link>
                        <Link to={"/panelAdmin/MainTowx"}>
                          <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">زیر گروه اول</p>
                            </a>
                          </li>
                        </Link>

                        <Link to={"/panelAdmin/ListItem"}>
                          {" "}
                          <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">زیر گروه دوم</p>
                            </a>
                          </li>
                        </Link>

                        <Link to={"/panelAdmin/ToggleImg"}>
                          {" "}
                          <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">محتوای دروس</p>
                            </a>
                          </li>
                        </Link>
                      </ul>
                    </li>
                  </ul>

                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-accordion="false"
                    data-widget="treeview"
                    role="menu"
                  >
                    <li className="nav-item has-treeview menu-open">
                      <a className="nav-link active" href="#">
                        <i className="nav-icon fa fa-dashboard" />
                        <p className="pok">
                          مدیریت آزمون
                          <i className="right fa fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                      <Link to={"/panelAdmin/Question"}>
                          <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">مدیریت تست ها</p>
                            </a>
                          </li>
                      </Link>
                        <Link to={"/panelAdmin/ManagePageTest"}>
                          <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">مدیریت آزمون ها</p>
                            </a>
                          </li>
                        </Link>
                      </ul>
                    </li>
                  </ul>

                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-accordion="false"
                    data-widget="treeview"
                    role="menu"
                  >
                    <li className="nav-item has-treeview menu-open">
                      <a className="nav-link active" href="#">
                        <i className="nav-icon fa fa-dashboard" />
                        <p className="pok">
                          مدیریت کاربران
                          <i className="right fa fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <Link to={"/panelAdmin/UsersList"}>
                          <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">لیست کاربران</p>
                            </a>
                          </li>
                        </Link>
                       <Link to={"/panelAdmin/ListAdmin"}>
                          <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">لیست مدیران</p>
                            </a>
                          </li>
                       </Link>
                      </ul>
                    </li>
                  </ul>

                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-accordion="false"
                    data-widget="treeview"
                    role="menu"
                  >
                    <li className="nav-item has-treeview menu-open">
                      <a className="nav-link active" href="#">
                        <i className="nav-icon fa fa-dashboard" />
                        <p className="pok">
                          آمار و گزارشات
                          <i className="right fa fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                      <Link to={"/panelAdmin/ReportAll/R-Ques"}>
                          <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">داشبورد گزارشات</p>
                            </a>
                          </li>
                        </Link>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">گزارشات کاربری</p>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">گزارشات تست ها</p>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">گزارشات آزمون ها</p>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#">
                              <i className="fa fa-circle-o nav-icon" />
                              <p className="pok">گزارشات دروس</p>
                            </a>
                          </li>
                      </ul>
                    </li>
                  </ul>

                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-accordion="false"
                    data-widget="treeview"
                    role="menu"
                  >
                    <li className="nav-item has-treeview menu-open">
                      <a className="nav-link active" href="#">
                        <i className="nav-icon fa fa-dashboard" />
                        <p className="pok">
                          مدیریت امنیت
                          <i className="right fa fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a className="nav-link" href="#">
                            <i className="fa fa-circle-o nav-icon" />
                            <p className="pok">تغییر رمز عبور</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </aside>
        </div>
      </body>
    </>
  );
};
