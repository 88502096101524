import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import del from "../asset/img/del.png";
import edit from "../asset/img/edit2.png";
import plus from "../asset/img/plus.png";
import {Helmet} from "react-helmet";

export const ManageTest = () => {
  const [tokenID, settokenID] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    settokenID(Math.floor(Math.random() * 58210208) + 15 * 2 * 8);
  }, []);

  const [less, setless] = useState([]);

  useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://test.kodina.ir/listAllR");
      setless(await res.clone().json());
    };
    getAllFiled();
  }, [less]);

  const handleDelete = (id) => {
    axios
      .delete(`https://test.kodina.ir/deleteOneR/${id}`)
      .then((response) => {
        setless((values) => {
          return values.filter((item) => item.id !== id);
        });
        toast.success("حذف  با موفقیت انجام شد");
      });
  };

  const updateRecord = (id) =>{
    toast.success("ویرایش انجام شد")
  }

  const [one, setOne] = useState("");
  const [two, setTwo] = useState("");
  const [tree, setTree] = useState("");
  const [num, setNum] = useState(10);

  const handelRegister = () => {
    axios.post("https://test.kodina.ir/RegisterREG", {
      token: tokenID,
      one: one,
      two: two,
      tree: tree,
      num: num,
    });
    toast.success("با موفقیت ثبت شد");
  };

  return (
    <>
    <Helmet>
      <title>تنظیمات آزمون</title>
    </Helmet>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="content-header pku">
              <div className="container-fluid">
                <div className="row">
                  {/*                 <p>کد آزمون : </p>
                <p className="mr-2" style={{color:"blue"}}>{tokenID}</p>
                <br/> */}
                  <p className="mr-1 mt-1 pok"> تعداد کل سوالات آزمون : </p>
                  <p className="mr-2" style={{ color: "blue" }}>
                    <input
                    style={{borderRadius:"20px"}}
                      type="text"
                      className="form-control pok"
                      value={num}
                      onChange={(e) => setNum(e.target.value)}
                    />
                  </p>
                  <td>
                    {" "}
                    <button style={{borderRadius:"20px"}} className="btn btn-outline-warning mr-2 pok">تایید</button>
                  </td>
                </div>

                <hr />
                <div className="row mb-2">
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <select
                    style={{borderRadius:"20px"}}
                      id="selce"
                      onChange={(e) => setOne(e.target.value)}
                      className="form-control pok"
                      name="unitRF"
                    >
                      <option className="pok" value={"0"}>تعداد سوال</option>
                      <option className="pok" value={"1"}>1</option>
                      <option className="pok" value={"2"}>2</option>
                      <option className="pok" value={"3"}>3</option>
                      <option className="pok" value={"4"}>4</option>
                      <option className="pok" value={"5"}>5</option>
                      <option className="pok" value={"6"}>6</option>
                      <option className="pok" value={"7"}>7</option>
                      <option className="pok" value={"8"}>8</option>
                      <option className="pok" value={"9"}>9</option>
                      <option className="pok" value={"10"}>10</option>
                    </select>
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <select
                    style={{borderRadius:"20px"}}
                      id="selce"
                      onChange={(e) => setTwo(e.target.value)}
                      className="form-control pok"
                      name="unitRF"
                    >
                      <option className="pok" value={"0"}>وضعیت دروس</option>
                      <option className="pok" value={"خوانده شده"}>خوانده شده</option>
                      <option className="pok" value={"خوانده نشده"}>خوانده نشده</option>
                      <option className="pok" value={"تصادفی"}>تصادفی</option>
                    </select>
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <select
                    style={{borderRadius:"20px"}}
                      id="selce"
                      onChange={(e) => setTree(e.target.value)}
                      className="form-control pok"
                      name="unitRF"
                    >
                      <option className="pok" value={"0"}>درجه سختی</option>
                      <option className="pok" value={"آسان"}>آسان</option>
                      <option className="pok" value={"متوسط"}>متوسط</option>
                      <option className="pok" value={"سخت"}>سخت</option>
                    </select>
                  </div>
                  <div class="col-xs-12 col-sm-1 mt-3">
                    <img
                      src={plus}
                      height="30px"
                      width="30px"
                      className="mr-2"
                      style={{ cursor: "pointer" }}
                      onClick={handelRegister}
                    />
                  </div>
                </div>
              </div>
              <p></p>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th className="pok">ردیف</th>
                      <th className="pok">الویت</th>
                      <th className="pok">تعداد سوال</th>
                      <th className="pok">وضعیت دروس</th>
                      <th className="pok">درجه سختی</th>
                      <th className="pok">ویرایش/حذف</th>
                      <th></th>
                    </tr>
                  </thead>
                  {less.map((item, index) => {
                    const { id } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td className="pok">{index + 1}</td>
                          <td className="pok">{5}</td>
                          <td className="pok">{item.one}</td>
                          <td className="pok">{item.two}</td>
                          <td className="pok">{item.tree}</td>

                          <td>
                            <img
                              src={edit}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => updateRecord(id)}
                            />
                            <img
                              src={del}
                              height="30px"
                              width="30px"
                              className="mr-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(id)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </body>
      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
