import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import eye from "../asset/img/eye.png";
import eye1 from "../asset/img/eye1.png";
import Modal from "react-bootstrap/Modal";
import { ExcelQuesOne } from './ExcelQuesOne'
import { ExcelQuesTwo } from './ExcelQuesTwo'


export const ReportQues = () => {

  const fileName = "گزارش دسته آزمون";
  const filename1 = "گزارش سوالات دسته آزمون";

 const [mainList, setMainList] = useState([]);
    useEffect(() => {
      const getAllProductsRT = async () => {
        const res = await fetch("https://test.kodina.ir/panelAdmin/listMainReper");
        setMainList(await res.json());
      };
      getAllProductsRT();
    }, [mainList]);

    const [showX, setShowX] = useState(false);
    const handleCloseX = () => setShowX(false);
    const handleShowX = () => setShowX(true);
    let newString;
    const [Ques, setQues] = useState([]);

    const showQues = async(id) => {
      const res = await fetch(`https://test.kodina.ir/panelAdmin/questionFinde/${id}`);
      setQues(await res.json());
      handleShowX(true)
    }
    
  const [search,setSearch] = useState('')

 return (
    <>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          
          <div className="content-wrapper pku">
            <div className="content-header ">
              <div className="container">
                <div className="row mb-2">
                <div className="col-12 col-sm-6 col-md-3">
  <Link to={"/panelAdmin/ReportAll/R-Ques"}>
    <div className="info-box">
      <span className="info-box-icon bg-info elevation-1">
      <i className="fa fa-leanpub" />
      </span>
      <div className="info-box-content">
        <span className="info-box-text pok">
          گزارش آزمون
        </span>
      </div>
    </div>
    </Link>
  </div>
  
  <div className="col-12 col-sm-6 col-md-3">
  <Link to={"/panelAdmin/ReportAll/AnswerQ"}>
    <div className="info-box mb-3">
      <span className="info-box-icon bg-danger elevation-1">
      <i className="fa fa-leanpub" />
      </span>
      <div className="info-box-content">
        <span className="info-box-text pok">
          گزارش سوالات
        </span>
      </div>
    </div>
    </Link>
  </div>
  <div className="clearfix hidden-md-up" />
  <div className="col-12 col-sm-6 col-md-3">
    <div className="info-box mb-3">
      <span className="info-box-icon bg-success elevation-1">
        <i className="fa fa-leanpub" />
      </span>
      <div className="info-box-content">
        <span className="info-box-text pok">
          گزارش آزمونهای انجام شده
        </span>
      </div>
    </div>
  </div>
 
    <div className="col-12 col-sm-6 col-md-3">
    <Link to={"/panelAdmin/ReportAll/R-User"}>
    <div className="info-box mb-3">
      <span className="info-box-icon bg-warning elevation-1">
        <i className="fa fa-users" />
      </span>
      <div className="info-box-content">
        <span className="info-box-text pok">
          گزارش کاربران
        </span>
      </div>
    </div>
    </Link>
  </div>
                  </div>
                    </div>
                    </div>
                    <hr />
                   <div className="App">
                   <ExcelQuesOne apiData={mainList} fileName={fileName} />
                   </div>
                    <hr />
    <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th className="pok">ردیف</th>
                      <th className="pok">عنوان</th>
                      <th className="pok" style={{textAlign:'center'}}>سوالات این دسته</th>
                      <th className="pok" style={{textAlign:'center'}}>آزمونهای این دسته</th>
                    </tr>
                  </thead>
                  {mainList.map((item, index) => {
                    const { id, title, img } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.title}</td>
                          <td style={{textAlign:'center'}}>
                            <img
                              src={eye}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                               onClick={() => showQues(title)} 
                            />
                          </td>
                          <td style={{textAlign:'center'}}>
                            <img
                              src={eye1}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              /* onClick={() => listDelete(id)} */
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
    </div>
    
    </div>
    </body>

    <Modal 
    show={showX} onHide={handleCloseX} 
    size="lg"  centered
    style={{marginRight:'100px'}}
    
    >
        <Modal.Body>
        <div className="row">
        <label className="m-1">جستجو براساس  درجه سختی: </label>
        <input type="text" maxLength={10} className="form-control col col-sm-3 m-1 text-center mr-2" onChange={(e) => setSearch(e.target.value)} />
        <ExcelQuesTwo apiData={Ques} fileName={filename1} />
        </div>
        <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>ردیف</th>
                      <th>سوال</th>
                      <th>درجه سختی</th>
                      <th>پاسخ یک</th>
                      <th>پاسخ دو</th>
                      <th>پاسخ سه</th>
                      <th>پاسخ چهار</th>
                      <th>پاسخ صحیح</th>
                    </tr>
                  </thead>
                  {Ques.filter((val) => {
                            if (search == "") {
                              return val;
                            } else if (
                              val.hard
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return val;
                            }
                          })
                  .map((item, index) => {
                      const regex = /(<([^>]+)>)/gi;
                      newString = item.ques.replace(regex, "");
                    const { id, title, img } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td>{index + 1}</td>
                          <td>{newString}</td>
                          <td>{item.hard}</td>
                          <td>{item.ques1}</td>
                          <td>{item.ques2}</td>
                          <td>{item.ques3}</td>
                          <td>{item.ques4}</td>
                          <td>{item.trueq.replace("ques","")}</td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

    </>
  )
}
