import React, { useEffect, useState } from "react";
import "../Main/Main.css";
import "../Main/global.css";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../HeaderMain/Header";

export const Main = () => {
  const location = useLocation();
  const name = location.state.name;
  const userId = location.state.userId;
  
  const [mainList, setMainList] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch("https://test.kodina.ir/panelAdmin/listMain");
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, []);

  const gotopage = (name, onv) => {
    nav("/Mainto", { state: { name, onv,userId } }); /* ,{state:{name}} */
  };

  return (
    <>
      <div className="lotteryactivedf" dir="ltr">
        <div className="primary-platesdf">
          <div className="lottery-pages-vertical-slidingdf">
            {mainList.map((item) => {
              const { id, title } = item;
              return (
                <div
                  className="lottery-itemsdf"
                  onClick={() => gotopage(name, title)}
                >
                  <div className="text3df">
                    {item.title}
                    {/* {location.state.name} */}
                  </div>
                  <div className="frame-divdf">
                    <img alt="" className="png-icon5df" src={item.img} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Header />
    </>
  );
};
