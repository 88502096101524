import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import del from "../asset/img/del.png";
import edit from "../asset/img/edit2.png";
import {Helmet} from "react-helmet";
import "../Main/ipc.css"
export const MainTowx = () => {
  const [title, setTitle] = useState("");
  const [descs, setDescs] = useState("");
  const [main_id, setMainid] = useState("");

  const registerO = async () => {
    try {
      if (!title) {
        toast.info("لطفا تمامی موارد خواسته شده رو پر نمائید");
      } else {
        if (file1.length <= 0) {
          axios
            .post("https://test.kodina.ir/panelAdmin/Mainxtow2", {
              title: title,
              main_id: main_id,
            })
            .then((res) => {
              toast.success("اطلاعات با موفقیت ثبت شد");
            })
            .catch((err) => {
              toast.error("خطا در عکس بارگذاری شده");
              console.log("Error :" + err);
            });
        } else {
          const formdata = new FormData();
          formdata.append("title", title);
          formdata.append("main_id", main_id);
          formdata.append("image", file1);
          axios
            .post("https://test.kodina.ir/panelAdmin/Mainxtow", formdata)
            .then((res) => {
              toast.success("اطلاعات با موفقیت ثبت شد");
            })
            .catch((err) => {
              toast.error("خطا در عکس بارگذاری شده");
              console.log("Error :" + err);
            });
        }
      }
    } catch (e) {
      toast.info("امکان ثبت دوباره وجود ندارد");
    }
  };

  const [filed, setFiled] = useState([]);

  /*
     useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://test.kodina.ir/panelAdmin/listMaintow");
      setFiled(await res.clone().json());
    };
    getAllFiled();
  }, [filed]); */

  const handleDelete = (id) => {
    axios
      .delete(`https://test.kodina.ir/panelAdmin/listDeletetow/${id}`)
      .then((response) => {
        setFiled((values) => {
          return values.filter((item) => item.id !== id);
        });
        toast.success("حذف  با موفقیت انجام شد");
      });
  };

  const [less, setless] = useState([]);

  useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://test.kodina.ir/panelAdmin/listMain");
      setless(await res.clone().json());
    };
    getAllFiled();
  }, [filed]);

  const [file1, setfile1] = useState("");

  const handleFile1 = (e) => {
    setfile1(e.target.files[0]);
  };

  /*   const updateTwoImage = async () => {
    if (!fileTwo) {
      alert("Error Select Image");
    } else {
      const formdata = new FormData();
      formdata.append("image", fileTwo);
      await axios
        .put(
          `https://test.kodina.ir/panelAdmin/updateVategoryT/${"100"}`,
          formdata
        )
        .then((res) => {
          toast.success("بارگزاری عکس با موفقیت انجام شد");
        });
    }
  }; */

  const getItemTree = async (id) => {
    try {
      console.log("sssssssssssss :" + id);
      const res = await fetch(
        `https://test.kodina.ir/panelAdmin/listMain2/${id}`
      );
      setFiled(await res.json());
    } catch (err) {}
  };

  const updateTwo = async (id) => {
    try {
      if (!title) {
        toast.warning("مقدار عنوان نباید خالی باشد");
      } else {
        axios
          .put(`https://test.kodina.ir/panelAdmin/updateTwo/${id}`, {
            title: title,
          })
          .then((res) => {
            toast.success("ویرایش با موفقیت انجام شد");
          });
      }
    } catch (Err) {
      toast.error("خطا در ویرایش  - لطفا دوباره سعی کنید");
    }
  };

  return (
    <>
    <Helmet>
      <title>زیر گروه اول</title>
    </Helmet>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="content-header pku">
           
              {filed.map((item, index) => {
                const { id } = item;
                return item.img != null ? (
                  <div class="col-xs-12 col-sm-5 mt-3">
                    {" "}
                   {/*  <img src={item.img} width={"100px"} height={"100px"} /> */}
                  </div>
                ) : null;
              })}
              <div className="container-fluid">
              <div className="row mb-4">
              <div class="col-xs-12 col-sm-3 mt-3">
                <input
                style={{borderRadius:"20px"}}
                  type="file"
                  name="fileTwo"
                  className="form-control"
                  onChange={handleFile1}
                />
              </div>
              <div class="col-xs-12 col-sm-2 mt-3">
                    <label className="pok" style={{marginTop:"10px", color:"green"}}>
                      اندازه پیشنهادی:<span style={{color:"red"}} className="pok mt-5">290*550</span>
                    </label>
                  </div>
              <div class="col-xs-12 col-sm-1 mt-3">
                  <img src="/img/-olimage-8@2x.png" width={'60px'} height={'60px'} style={{borderRadius:"50%"}}/>
                  </div>
                  <div class="col-xs-12 col-sm-1 mt-3">
                  <img src="/img/image-15@2x.png" width={'60px'} height={'60px'} style={{borderRadius:"50%"}}/>
                  </div>
                  <div class="col-xs-12 col-sm-1 mt-3">
                  <img src="/img/image-17@2x.png" width={'60px'} height={'60px'} style={{borderRadius:"50%"}}/>
                  </div>
                  <div class="col-xs-12 col-sm-2 mt-3">

                    <button style={{borderRadius:"20px"}} className="btn btn-outline-success pok">
                       تغییر عکس
                    </button>
                  </div>
</div>
</div>


              <div className="container-fluid">
                <div className="row mb-2">
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <select style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => setMainid(e.target.value)}
                      onClick={() => getItemTree(main_id)}
                      name="fieldID"
                    >
                      <option style={{borderRadius:"20px"}} className="pok" value={"23e0w"}>انتخاب کنید</option>

                      {less.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                      <option value={"all"}>All</option>
                    </select>
                  </div>

                  <div class="col-xs-12 col-sm-3 mt-3">
                    <input
                      type="text"
                      className="form-control pok"
                      placeholder="عنوان"
                      onChange={(e) => setTitle(e.target.value)}
                      style={{borderRadius:"20px"}}
                    />
                  </div>

                  <div class="col-xs-12 col-sm-3 mt-3">
                    <button style={{borderRadius:"20px"}} className="btn btn-outline-success pok" onClick={registerO}>
                      ایجاد کردن
                    </button>
                  </div>
                  
                </div>
              </div>
              <hr />

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                    <th className="pok">گروه اصلی</th>
                    <th className="pok">عنوان</th>
                      <th className="pok">ردیف گروه</th>
                      <th className="pok">ویراش / حذف</th>
                     
                    </tr>
                  </thead>
                  {filed.map((item, index) => {
                    const { id } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td className="pok">{index + 1}</td>
                          <td className="pok">{item.main_id}</td>
                          <td className="pok">{item.title}</td>
                          <td>
                            <img
                              src={edit}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => updateTwo(id)}
                            />
                          </td>
                          <td>
                            <img
                              src={del}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(id)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            
            </div>
          </div>
        </div>
      </body>

      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
