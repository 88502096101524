import React, { useEffect, useState } from "react";
import "../More/More.css";
import "../More/global.css";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../Header/Header";
import moment from "jalali-moment";
import axios from 'axios'

export const More = () => {
  const location = useLocation();
  const nav = useNavigate();

  const name = location.state.name;
  const onv = location.state.onv;
  const gru = location.state.gru;
  const title = location.state.title;
  const userId = location.state.userId;

  const [mainList, setMainList] = useState([]);

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch(
        `https://test.kodina.ir/panelAdmin/searchItemMainlists/${gru}`
      );
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, []);

  const [times,setTimes] = useState(1)
 
  useEffect(() => {
    const interval = setInterval(() => {
        setTimes(times + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [times]);

  const gobackpage = () => {
    axios.post("https://test.kodina.ir/panelAdmin/saveTwo",{
      one:name,
      two:onv,
      tree:gru,
      four:title,
      five:'Test' /* mainList.map((item) =>item.descs) */,
      times:times,
      date: moment(new Date(), "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD"),
    })    

    nav("/Toggle", { state: { name, onv, gru, title,userId } });
  };

  const gotopageU = (title) => {
    nav("/Overview", { state: { name, onv,userId } });
  };

  const gotopageB = (gru) => {
    nav("/Toggle", { state: { name, onv, gru, title,userId } }); // یک سی بی سی
  };

  return (
    <>
      <div className="about-uszx" dir="ltr">
      <div class="profile4kl">Dental Panoramic
          <p style={{textAlign:'center'}}>R A D I O L O G Y</p>
      </div>
        <div
          className="swatch99zx"
        >
        <div className="po5"><span style={{color:'white'}}>{title}</span></div>
        </div>
        <br />
        <div className="swatch100zx">
          <br />
          <div className="lorem-ipsum-dolor5zx">
            {mainList.map((item) => {
              const { id, title } = item;
              return <>{item.descs}</>;
            })}
          </div>
        </div>
        <div className="sign-in-btn2zx" onClick={gobackpage}>
          <div className="button-text2zx">
            <div className="label2zx">Ok</div>
          </div>
        </div>
      </div>

      <div class="profile4" hidden>{gru}</div>
      <a class="swatch-parent60" onClick={() => gotopageU(title, name)}>
        <img alt="" src="/img/Group 5551.png" />
      </a>
      <a class="swatch-parent62" onClick={() => gotopageB(gru)}>
        <img alt="" src="/img/Group 5550.png" />
      </a>
      <div class="swatch-parent63">
        <div class="swatch101"></div>
        <img class="person-icon22" alt="" src="/img/person.svg" />
      </div>
    </>
  );
};
