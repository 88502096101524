import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { PieChart } from '@mui/x-charts/PieChart';


export const ReportChart = () => {

    const [mainList, setMainList] = useState([]);
  
    useEffect(() => {
      const getAllProductsRT = async () => {
        const res = await fetch("https://test.kodina.ir/panelAdmin/testCharts");
        setMainList(await res.json());
      };
      getAllProductsRT();
    }, [mainList]);

  
    // id,token,ques,grid,ans_true,ans_false,date,times
    return (
      <>
        <body
          className="hold-transition sidebar-mini"
          style={{ backgroundImage: "none" }}
        >
          <div className="wrapper">
            <div className="content-wrapper">
              <div className="content-header">
                <div className="container-fluid"></div>
        
                <PieChart
                   series={[
                      {
                          data: [
                                { id: 1, value: 15, label: 'series B' },
                                { id: 2, value: 20, label: 'series C' },
                          ],
                      },
                    ]}

                    width={400}
                    height={200}
                />

              </div>
            </div>
          </div>
        </body>
  
        <ToastContainer
          position="top-right"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </>
    );
  };
  