import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import del from "../asset/img/del.png";
import edit from "../asset/img/edit2.png";
import Modal from "react-bootstrap/Modal";
import {Helmet} from "react-helmet";

export const ListItem = () => {
  
  const [field, setfield] = useState([]);
  const [less, setless] = useState([]);
  const [main_id, setMainid] = useState("");
  const [title, setTitle] = useState("");
  const [descs, setDescs] = useState("");
  const [maintow_id, SetMaintow_id] = useState("");

  const registerL = () => {
    try {
      if (!title || !main_id) {
        toast.warning("لطفا تمامی موارد خواسته شده را پر نمائید");
      } else {
        axios
          .post("https://test.kodina.ir/panelAdmin/Mainxlist", {
            title: title,
            descs: descs,
            main_id: main_id,
            maintow_id: maintow_id,
          })
          .then(() => {
            toast.success("ثبت با موفقیت انجام شد");
          })
          .catch((e) => {
            toast.warning("فیلد نام تکراری میباشد");
          });
      }
    } catch (e) {
      toast.error("خطای عملیاتی لطفا با کارشناس سیستم تماس حاصل فرمائید");
    }
  };

  const [filed, setFiled] = useState([]);

  /*   useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://test.kodina.ir/panelAdmin/listMainlist");
      setFiled(await res.clone().json());
    };
    getAllFiled();
  }, [filed]); */

  const handleDelete = (id) => {
    axios
      .delete(`https://test.kodina.ir/panelAdmin/listDeletelist/${id}`)
      .then((response) => {
        setFiled((values) => {
          return values.filter((item) => item.id !== id);
        });
        toast.success("حذف  با موفقیت انجام شد");
      });
  };

  useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://test.kodina.ir/panelAdmin/listMain");
      setfield(await res.clone().json());
    };
    getAllFiled();
  }, []);

  const [found, setFound] = useState([]);

  const getItemTree = async (id) => {
    try {
      const res = await fetch(
        `https://test.kodina.ir/panelAdmin/searchItemMainTow/${id}`
      );
      setFound(await res.json());
    } catch (err) {}
  };

  const getItemTreeAll = async (id) => {
    try {
      const res = await fetch(
        `https://test.kodina.ir/panelAdmin/listMainlist2/${id}`
      );
      setFiled(await res.json());
    } catch (err) {}
  };

  const updateTwo = async (id) => {
    try {
      if (!title) {
        toast.warning("مقدار عنوان نباید خالی باشد");
      } else {
        axios
          .put(`https://test.kodina.ir/panelAdmin/updateTree/${id}`, {
            title: title,
          })
          .then((res) => {
            toast.success("ویرایش با موفقیت انجام شد");
          });
      }
    } catch (Err) {
      toast.error("خطا در ویرایش  - لطفا دوباره سعی کنید");
    }
  };

  const [showBR14, setShowBR14] = useState(false);
  const handleCloseBR14 = () => setShowBR14(false);
  const handleShowBR14 = () => setShowBR14(true);

  const [listPay, setlistPay] = useState([]);
  const [filterAllET, setFilterAllET] = useState(listPay);

  const showHandelpopsocial = async (id) => {
    const res = await fetch(
      `https://test.kodina.ir/panelAdmin/SearchTestHard/${id}`
    );
    setlistPay(await res.clone().json());
    setFilterAllET(await res.json());
    handleShowBR14(true);
  };

  return (
    <>
    <Helmet>
      <title>زیر گروه دوم</title>
    </Helmet>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="content-header pku">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <select 
                      style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => setMainid(e.target.value)}
                      onClick={() => getItemTree(main_id)}
                      name="main_id"
                    >
                      <option value={"23e0w"} className="pok">انتخاب کنید</option>
                      {field.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                  </div>
                  <div  class="col-xs-12 col-sm-3 mt-3 pok">
                    <select
                    style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control"
                      onChange={(e) => SetMaintow_id(e.target.value)}
                      onClick={() => getItemTreeAll(maintow_id)}
                      name="maintow_id"
                    >
                      <option className="pok" value={"0wss0"}>انتخاب کنید</option>
                      {found.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                  </div>

                  <div class="col-xs-12 col-sm-3 mt-3 pok">
                    <input
                    style={{borderRadius:"20px"}}
                      type="text"
                      className="form-control"
                      placeholder="عنوان"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div class="col-xs-12 col-sm-12 mt-3 pok">
                    <textarea
                    style={{borderRadius:"20px"}}
                      rows={5}
                      className="form-control"
                      placeholder="شرح"
                      onChange={(e) => setDescs(e.target.value)}
                    />
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3 pok">
                    <button className="btn btn-outline-success" onClick={registerL} style={{borderRadius:"20px"}}>
                      ایجاد کردن
                    </button>
                  </div>
                </div>
              </div>
              <hr />

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th className="pok">ردیف</th>
                      <th className="pok">گروه اصلی</th>
                      <th className="pok">زیر گروه اول</th>
                      <th className="pok">زیر گروه دوم</th>
                      <th className="pok">نمایش تست</th>
                      <th className="pok">ایجاد تست</th>
                      <th className="pok">ویرایش/حذف</th>
                    </tr>
                  </thead>
                  {filed.map((item, index) => {
                    const { id, main_id, maintow_id, title } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td className="pok">{index + 1}</td>
                          <td className="pok">{item.main_id}</td>
                          <td className="pok">{item.maintow_id}</td>
                          <td className="pok">{item.title}</td>
                          <td className="pok" onClick={() => showHandelpopsocial(maintow_id)}>
                            <Link>درجه سختی</Link>
                          </td>
                          <td className="pok">
                            <Link>تست جدید</Link>
                          </td>
                          <td>
                            <img
                              src={edit}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => updateTwo(id)}
                            />
                                                        <img
                              src={del}
                              height="30px"
                              width="30px"
                              className="mr-3"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(id)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </body>

      <Modal show={showBR14} onHide={handleCloseBR14} centered>
        <Modal.Body>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th className="pok">ردیف</th>
                  <th className="pok">درجه سوال</th>
                  <th className="pok">وضعیت</th>
                </tr>
              </thead>
              {filterAllET.map((item, index) => {
                const { id } = item;
                return (
                  <tbody
                    class="table-group-divider table-divider-color"
                    key={item.id}
                  >
                    <tr>
                      <td className="pok">{index + 1}</td>
                      <td className="pok">{item.hard}</td>
                      <td className="pok">تعریف شده</td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
