import React, { useEffect, useState } from "react";
import "../Toggle/global.css";
import "../Toggle/OnToggle.css";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-js-switch";
import { Header } from "../Header/Header";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "../Toggle/style.css";
import moment from "jalali-moment";
import axios from 'axios'

export const Toggle = () => {
  const location = useLocation();
  const nav = useNavigate();

  const name = location.state.name;
  const onv = location.state.onv;
  const gru = location.state.gru;
  const title = location.state.title;
  const userId = location.state.userId;

  const [times,setTimes] = useState(1)
 
  useEffect(() => {
    const interval = setInterval(() => {
        setTimes(times + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [times]);

  const gotopage = (name, onv, gru, title) => {
    axios.post("https://test.kodina.ir/panelAdmin/saveOne", {
      toga:name,
      togb:onv,
      togc:gru,
      togd:title,
      toge:'1',
      times:times,
      date: moment(new Date(), "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD"),
    })
    nav("/More", { state: { name, onv, gru, title,userId } });
  };

  const [isSwitchOn, setIsSwitchOn] = useState(true);

  const switch_onChange_handle = () => {
    setIsSwitchOn(!isSwitchOn);
    if (isSwitchOn === true) {
      console.log("True Checked");
    } else {
      console.log("False Checked");
    }
  };

  const [mainList, setMainList] = useState([]);

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch(
        "https://test.kodina.ir/panelAdmin/listMaintoggle"
      );
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, []);

  // 0.1 opacity
  const [opa, setopa] = useState(1);

  const gotopageU = (titleV) => {
    nav("/Overview", { state: { name, onv,userId } });
  };

  const gotopageB = (gruV) => {
    nav("/list", { state: { name, onv, gru,userId } }); // یک سی بی سی
  };

  const MAX = 100;

  const [value, setValue] = useState(0);

  const getBackgroundSize = () => {
    /*     if(value >= 99){
      return (
        <>
        <img
        className="image-14-iconb"
        src={"/img/q2.png"}
      />
      </>
      )
    } */
    return { backgroundSize: `${(value * 100) / MAX}% 100%` };
  };

  return (
    <>
      <div className="on-toggleb">
      <div class="profile4kl">Dental Panoramic
          <p style={{textAlign:'center'}}>R A D I O L O G Y</p>
      </div>
        <div className="swatch9b"/>
        <div className="po3"><span style={{color:'white'}}>{title}</span></div>
        <div className="angel-of-theb" style={{ textAlign: "center" }}></div>

        <div className="swatch10b" />

        <div className="slider-default-parentb">
          <div className="slider-default1b">
            <input
              className="slider-default1b"
              dir="ltr"
              type="range"
              min="0"
              max={MAX}
              onChange={(e) => setValue(e.target.value)}
              style={getBackgroundSize()}
              value={value}
            />
          </div>
          <div className="realb">Real</div>
          <div className="x-rayb">X-Ray</div>
        </div>
              <div className="toggle-parentb">
{/*               <div className="po2" style={{ marginTop:"-20px",}}>{title}</div> */}
              
              <div style={{ textAlign: "left",marginLeft:"-18px", opacity:"0%"}}>{title}</div>
              <div style={{ textAlign: "left",marginLeft:"-18px", marginTop:"-6px", opacity:"0%"}}>{title}</div>
              

                <Switch onChange={switch_onChange_handle} className="x-rayb3" />
              <div className="realb3">Show Problem</div>
        </div>
        <div
          className="button-textb"
          onClick={() => gotopage(name, onv, gru, title)}
        >
          <div className="labelb">More Information</div>
        </div>
      </div>

      {isSwitchOn === true ? (
        <img
          alt="No"
          style={{ opacity: opa }}
          className="image-14-iconb"
          src={"/img/qq.png"}
        />
      ) : (
        <img
          alt="No"
          style={{ opacity: opa }}
          className="image-14-iconb"
          src={"/img/image-8@2x.png"}
        />
      )}

      {value >= 99 && isSwitchOn === true ? (
        <img
          alt="No"
          style={{ opacity: "90%" }}
          className="image-14-iconb"
          src={"/img/q2.png"}
        />
      ) : null}

      <div class="profile4" hidden>{gru}</div>
      <a class="swatch-parent60" onClick={() => gotopageU(onv, name)}>
        <img alt="" src="/img/Group 5551.png" />
      </a>

      <a class="swatch-parent62" onClick={() => gotopageB(onv, name)}>
        <img alt="" src="/img/Group 5550.png" />
      </a>
      <div class="swatch-parent63">
        <div class="swatch101"></div>
        <img class="person-icon22" alt="" src="/img/person.svg" />
      </div>
    </>
  );
};
